/* 🔹 How-To-Play Page Styling */
.how-to-play-screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url('../../public/smooch_host.jpg') no-repeat right center;
    background-size: cover; /* Ensures full coverage */
    background-attachment: fixed; /* Prevents doubling */
    padding: 20px;
}

/* 🟠 Title Section */
.how-to-play-title {
    background: linear-gradient(45deg, #ff1654, #ff8fab, #ffde59, #ff1654);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 4px 4px 10px rgba(255, 50, 100, 0.5);
    font-size: clamp(2rem, 4vw, 3rem);
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px;
    text-align: center;
    margin-top: 10%;
}

/* 🔹 Content Section */
.how-to-play-content {
    background: rgba(255, 255, 255, 0.3);
    padding: 20px;
    max-width: 600px;
    width: 90%;
    border-radius: 12px;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    text-align: left;
}

.how-to-play-content p {
    font-size: clamp(1rem, 2vw, 1.2rem);
    color: white;
    text-align: center;
    font-size: 1.2rem;
    background: rgba(0, 72, 255, 0.7);
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0px 0px 10px rgba(0, 106, 255, 0.5);
    margin-bottom: 20px;
}

.how-to-play-content ul {
    text-align: left;
    font-size: clamp(1rem, 1.8vw, 1.1rem);
    list-style-type: none;
    padding: 0;
    color:rgb(255, 255, 255);
}

.how-to-play-content ul li {
    background: rgba(0, 72, 255, 0.7);
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 106,s 255, 0.5);
    margin-bottom: 10px;
}

/* 🔹 Menu Button */
.menu-button {
    background: #ff1654;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    margin-top: 20px;
}

.menu-button:hover {
    background: #d01148;
    transform: scale(1.05);
}

/* 🔹 Mobile Optimizations */
@media (max-width: 768px) {

    .how-to-play-content {
        width: 95%;
        padding: 15px;
    }

    .menu-button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
